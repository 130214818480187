
<template>
    <div>
        <CSDialog :dialog-title="!addInspectionPointInfo.pointId ? '添加巡检点' : '修改巡检点'"
          :dialog-visible="dialogVisible" @onConfirm="saveInspectionPointInfo" @onClose="closeDialog"
          dialogWidth="720px" :is-submitting="isSubmitting"
        >
          <template v-slot:dialog-content>
            <div class="ibox-content">
              <div class="ibox-item">
                <label>巡检点</label>
                <div>
                    <input v-model="addInspectionPointInfo.name" type="text" placeholder="请输入" maxlength="10"/>
                </div>
              </div>
              <div class="ibox-item" v-if="!addInspectionPointInfo.pointId">
                <label>位置</label>
                  <div>
                      <ChooseLocation @changeLocation="changeLocation"/>
                  </div>
              </div>
            </div>
          </template>
        </CSDialog>

    </div>
</template>
<script>
import {createInspectionPointUrl, editInspectionPointUrl} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
import ChooseLocation from "@/components/ChooseLocation";

export default {
    components: { CSDialog, ChooseLocation },
    data() {
        return {
            dialogVisible: false,
            isSubmitting: false,
            addInspectionPointInfo: {
                regionId: this.$vc.getCurrentRegion().code,
                name: "",
                locationId: '',
            },
        };
    },

    mounted() {
        this.$vc.on(this.$route.path,
            "addInspectionPoint",
            "openAddInspectionPointModal",
            (params) => {
                this.dialogVisible = true;
                if (params) {
                    this.addInspectionPointInfo.name = params.name;
                    this.addInspectionPointInfo.pointId = params.id;
                    delete this.addInspectionPointInfo.locationId;
                } else {
                    this.addInspectionPointInfo = {
                        regionId: this.$vc.getCurrentRegion().code,
                        name: "",
                        locationId: '',
                    };
                }
            }
        );
    },
    methods: {
        closeDialog() {
          this.dialogVisible = false;
        },
        /**
         * 修改位置
         * @param {Object} location 位置信息
         * */
        changeLocation(location) {
            this.addInspectionPointInfo.locationId = location.id;
        },
        addInspectionPointValidate() {
            return this.$vc.validate.validate(
                {
                    addInspectionPointInfo: this.addInspectionPointInfo,
                },
                {
                    "addInspectionPointInfo.name": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "巡检点名称不能为空",
                        },
                    ],
                    "addInspectionPointInfo.locationId": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "位置不能为空",
                        }
                    ]
                }
            );
        },
        saveInspectionPointInfo () {
            const staffInfo = this.$vc.getCurrentStaffInfo();
            this.addInspectionPointInfo.regionId = this.$vc.getCurrentRegion().communityId;
            if (this.addInspectionPointInfo.pointId) {
                this.editInspectionPoint(staffInfo.id);
                return ;
            }
            if (!this.addInspectionPointValidate()) {
                this.$vc.toast(this.$vc.validate.errInfo);
                return;
            }
            this.isSubmitting = true;
            this.$fly.post(createInspectionPointUrl, {
                operatorId: staffInfo.id,
                checkPointOrgId: staffInfo.departmentCode,
                ...this.addInspectionPointInfo,
            }).then((res) => {
                if (res.code !== 0) {
                    return;
                }
                this.$vc.toast("添加成功");
                this.dialogVisible = false;
                this.clearAddInspectionPointInfo();
                this.$vc.emit(this.$route.path, "inspectionPointManage", "listInspectionPoint", 1);
            })
            .finally(() => this.isSubmitting = false);
        },
        /**
         * 编辑巡检点
         * @param {String | Number} operatorId 操作人id
         * */
        editInspectionPoint(operatorId) {
            this.$fly.post(editInspectionPointUrl, {
                operatorId,
                ...this.addInspectionPointInfo,
            }).then((res) => {
                if (res.code !== 0) {
                    return;
                }
                this.$vc.toast("修改成功");
                this.dialogVisible = false;
                this.clearAddInspectionPointInfo();
                this.$vc.emit(this.$route.path, "inspectionPointManage", "listInspectionPoint", 1);
            });
        },
        clearAddInspectionPointInfo: function () {
            this.addInspectionPointInfo = {
                regionId: this.$vc.getCurrentRegion().code,
                name: "",
                locationId: '',
            };
        },
    },
};
</script>
<style lang="stylus" scoped>
.ibox-content
    font-size 24px
    color #444
    padding 30px
    .ibox-item
        line-height 1
        &:not(:last-of-type)
            margin-bottom 24px
        & > label
            width 175px
            text-align right
            font-size 24px
            margin-right 40px
            vertical-align middle
            margin-bottom 0
            &.top
                vertical-align top
        & > div
            display inline-block
            vertical-align middle
            position relative
            input, select
                border-radius 6px
                font-size 24px
                padding 0 10px
                box-sizing border-box
                height 40px
                border 1px solid #999
            input
                width 400px
</style>
